<template>
  <div>
    <c-card title="업체마스터 일괄업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 적용 -->
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'education-coursen-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['vendorCd'], // 데이터들의 키 속성값
        taskClassCd: 'VENDOR_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.$comm.getComboItems('VENDOR_CLASS_CD').then(_result => {
        this.$comm.getComboItems('VENDOR_TYPE_CD').then(_result2 => {
          this.excelUploadInfo.columns = [[
            {
              name: 'vendorCd',
              label: '업체코드',
              align: 'center',
              sortable: false,
              style: 'width:130px',
              required: true,
            },
            {
              name: 'vendorName',
              label: '업체명',
              align: 'left',
              sortable: false,
              style: 'width:130px',
              required: true,
            },
            {
              name: 'bizNo',
              label: '사업자번호',
              align: 'center',
              sortable: false,
              style: 'width:130px',
              required: true,
            },
            {
              name: 'vendorClassCd',
              label: '업체분류',
              align: 'center',
              style: 'width:90px',
              sortable: false,
              required: true,
              type: 'select',
              comboItems: _result,
            },
            {
              name: 'vendorTypeCd',
              label: '업체유형',
              align: 'center',
              style: 'width:90px',
              sortable: false,
              required: true,
              type: 'select',
              comboItems: _result2,
            },
            {
              name: 'representName',
              label: '대표자명',
              align: 'center',
              sortable: false,
              style: 'width:130px',
            },
            {
              name: 'phoneNo',
              label: '대표전화번호',
              align: 'center',
              sortable: false,
              style: 'width:130px',
            },
            {
              name: 'email',
              label: '대표이메일',
              align: 'center',
              sortable: false,
              style: 'width:130px',
            },
            {
              name: 'chargeName',
              label: '담당자명',
              align: 'center',
              sortable: false,
              style: 'width:130px',
            },
            {
              name: 'address',
              label: '본사주소',
              align: 'center',
              sortable: false,
              style: 'width:300px',
            },
            {
              name: 'plantCds',
              label: '관련사업장',
              align: 'left',
              style: 'width:150px',
              type: 'plant',
              sortable: false,
              required: true,
            },
          ]]
        });
      });
      // url setting
      // code 
    // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '에러가 있는 데이터가 존재합니다.\n\r데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '해당 엑셀데이터를 업로드하면,\n\r동일한 업체코드는 정보가 수정됩니다 \n\r그대로 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
